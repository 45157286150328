<template>
  <div class="wrap">
    <div style="text-align: center;font-size: 16px;font-weight: bold; margin-bottom: 20px;">用户协议</div>
    <div class="title">
      特别提示
    </div><br/>
    <div class="privacyContent">
         <span class=" privacyContentColor">
上海茵朗信息科技有限公司（以下简称“趴趴教育”）在此特别提醒您，请认真阅读本《用户协议》（以下简称“本协议”），确保您充分理解本协议中各条款。在您审慎阅读后，您有权选择接受或不接受本协议。但您理解并知悉，除非您接受本协议所有条款，否则您无权注册、登录和/或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并受到本协议各项条款的约束。
    </span><br/><br/>
      <div style="text-decoration:underline">趴趴教育在此特别提醒您，本协议第八条对于趴趴教育在本协议项下的责任免除做了明确约定，请您认真阅读并知悉。</div>
    </div>
    <div class="fontWeight"></div>
    <div class="title">
      一、说明
    </div><br/>

    <div class="privacyContent">
         <span class=" privacyContentColor">
1. 本协议是您与趴趴教育就使用趴趴教育官方用户平台上提供的部分或全部本协议所涉服务所签订的协议。<br/>
2.您需要达到您所在国家/地区规定的法定成年年龄，方可签订本协议或使用本协议所涉服务；如果您未达到您所在国家/地区规定的法定成年年龄，请在法定监护人的陪同下阅读本协议并获得法定监护人的同意。如果您的法定监护人同意您使用本协议所涉服务，则您的法定监护人应当：<br/>
（1）同意监督您对本协议所涉服务的使用；<br/>
（2）了解并愿意承担您使用本协议所涉服务的全部相关风险；<br/>
（3）承担您使用本协议所涉服务造成的全部责任；<br/>
（4）保证您提交的全部信息的准确性和真实性；<br/>
（5）了解您对本协议所涉服务的接触和使用同样受本协议的约束。<br/>
3.您理解并承诺，在注册成为趴趴教育官方用户平台的用户之前，您已经认真阅读本协议并充分理解本协议中各条款。您理解您在审慎阅读本协议后，有权选择接受或不接受本协议。但除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br/>
    </span>

    </div>
    <br/>
    <div class="title">
      二、定义
    </div><br/>

    <div class="privacyContent">
         <span class=" privacyContentColor">
1.趴趴教育的官方用户平台：指上海茵朗信息科技有限公司所有和运营的隶属于趴趴教育品牌旗下的有关教育、学习等数字内容聚合、管理和分发的平台产品，包括但不限于：手机移动端的应用（App）、公众账号平台、小程序以及电脑（PC）端网站。<br/>
2.本协议所涉服务：指您通过趴趴教育的官方用户平台可获取和/或使用的全部功能和/或服务，包括如购买并观看趴趴教育提供的视频课程等服务在内的付费服务，以及如浏览趴趴教育官方平台上的各项资讯等在内的免费服务。<br/>
3.注册：指您通过趴趴教育的官方用户平台，按要求填写相关信息并确认同意履行本协议后取得趴趴教育的官方用户平台账号的过程。[为了用户便捷，我们设定通过手机号一键登录及通过手机号获取验证码登录这2种方式默认注册，是否需要详细说明呢]为了便于您的使用，在您初次采用趴趴教育官方平台提供的手机号码一键登录以及手机号码获取验证码这两种登陆方式时，趴趴教育将视为您同意本协议且实施了注册行为。<br/>
4.登陆：指您通过趴趴教育的官方用户平台提供的入口，填写您的账号名称及密码后，使用本协议所涉服务的过程。[关于登录，我们还有使用手机号一键登录及通过手机号获取验证码登录，这里是否需要详细说明区别一下呢]为了便于您的使用，您也可通过趴趴教育官方平台提供的手机号码一键登录以及手机号码获取验证码这两种方式登陆。<br/>
    </span>

    </div>
    <br/>

    <div class="title">
      三、用户权利义务
    </div><br/>

    <div class="privacyContent">
         <span class=" privacyContentColor">
1.您理解并知悉，在您使用本协议所涉服务前，您须自行配备上网所需的各项计算机软硬设备，并负担接续互联网及电话等相关费用。使用本协议所涉服务应遵守中国及用户联机所在地相关法令及互联网之国际使用惯例与礼节，并不得侵害任何第三人权益。本条所述侵害第三人权益的行为包括但不限于通过趴趴教育官方平台发布以下信息：<br/>
（1）含有任何性或性暗示的；<br/>
（2）含有辱骂、恐吓、威胁、诽谤内容的；<br/>
（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br/>
（4）涉及他人隐私、个人信息或资料的；<br/>
（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br/>
（6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br/>
2.您理解并承诺，在使用本协议所涉服务时您应当遵守中国及您联机所在地相关法律法规、互联网的国际使用惯例与礼节、本协议以及趴趴教育通过其他形式与您签署的一切其他规则和/或协议。<br/>
3.您理解并承诺，您无需注册即可开始使用部分本协议所涉服务。同时，您也理解，出于趴趴教育的商业目的，部分本协议所涉服务的使用要求您注册登陆并付费后方可使用。<br/>
4.您理解并承诺，您仅出于个人学习使用的目的，按照趴趴教育官方平台的提示，合法使用本协议所涉服务。但未经趴趴教育官方平台的明确许可提示或事先书面授权，您不得以任何形式复制、传播或交易本协议所涉服务内容。本协议所涉服务内容由趴趴教育所有，您在超出趴趴教育授权范围使用本协议所涉服务内容时须另行取得趴趴教育的书面许可。[关于”不得以任何形式下载”这个部分，因为我们App中对购买的用户提供下载功能，为了提供用户离线观看课程视频，是否会与这句话有所冲突。]<br/>
5.您理解并知悉，在您注册成为趴趴教育官方平台的用户后，应妥善保管您的用户账号及密码，避免账号及密码泄漏，亦不得将用户账号及密码提供给第三人使用（无论以有偿方式或无偿方式提供均）。对于因您未妥善保管您的用户帐号或密码导致的信息泄露以及由此造成的任何损失，趴趴教育不承担任何责任。如您发现任何非您本人作出的用户帐号使用行为，请立即通知趴趴教育。除非收到您相反的明确书面提示，趴趴教育将您的用户账号行为视为您本人的行为，并有权要求您对您的用户账号行为负责。<br/>
6.您理解并承诺，您不得通过趴趴教育官方平台和/或本协议所涉服务从事违法活动，且不得干扰或侵犯的趴趴教育官方平台的正常运行。本条所述禁止性行为包括但不限于：<br/>
（1）利用趴趴教育官方平台制作、复制、查阅和传播下列言论和/或信息：<br/>
.违反中国法律法规、社会公序良俗的；<br/>
.危害国家安全，损害国家荣誉和利益的；<br/>
.煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
.破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
.散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
.损害趴趴教育声誉和商业利益的；<br/>
.含有法律、行政法规禁止的其他内容。<br/>
（2）未经许可而非法进入其它个人或组织的电脑系统；<br/>
（3）未经允许，对趴趴教育官方平台中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；<br/>
（4）故意制作、传播计算机病毒等破坏性程序。<br/>
7.您理解并知悉，如因您违反有关法律或者本协议的约定，致使趴趴教育遭受任何损失、受到任何第三方的索赔和/或任何行政管理部门的处罚，您应对趴趴教育提供补偿。<br/>
    </span>
    </div>
    <br/>
    <div class="title">
      四、趴趴教育权利义务
    </div><br/>

    <div class="privacyContent">
          <span class=" privacyContentColor">
1.趴趴教育承诺在现有技术上维护趴趴教育官方平台的正常运行，并努力提升和改进技术，致力于为您更好地提供本协议所涉服务；<br/>
2.趴趴教育承诺对您在注册使用趴趴教育官方平台过程中所遇到的与本协议所涉服务有关的问题及反映的情况，及时作出回复；<br/>
3.趴趴教育承诺尽最大努力保证优秀的师资力量，以向您提供优质的本协议所涉服务；但您理解并知悉，趴趴教育有权根据实际运营情况变更本协议所涉服务的实际内容提供者并及时向您发出通知。<br/>
4.趴趴教育有权就您在趴趴教育官方平台上的不当行为或其它任何趴趴教育认为应当终止服务的情况，随时作出删除相关信息、终止提供服务等处理决定，而无须征得您的同意；<br/>
5.趴趴教育承诺持续向您提供本协议所涉服务，不随意中断或停止提供服务。但对于不可抗力或者其它非人为因素造成的服务的中断或停止，趴趴教育不承担任何相应的责任。<br/>
6.趴趴教育独立或与其他人著作权人共同享有趴趴教育官方平台上以任何形式表现的作品（包括但不限于文字、软件、声音、图片、录像、表格、电子邮件等）的著作权。未经趴趴教育书面许可，您不得擅自对趴趴教育官方平台上的包括课程视频在内的任何作品进行任何形式的翻录、复制或从事其他任何违反《中华人民共和国著作权法》等相关法律、法规的活动。趴趴教育保留依法追究任何侵犯趴趴教育知识产权的行为的法律责任的权利，且趴趴教育未采取相应的措施不得被视为放弃此项权利。<br/>
7.趴趴教育独立享有趴趴教育官方平台上以任何形式表现的专利、商标、外观设计等内容以及与提供本协议所涉服务相关的全部知识产权。未经趴趴教育事前书面许可，您不得以任何目的和方式使用，亦不得提供给任何第三人使用。<br/>
8.趴趴教育有权根据业务发展需要，通过趴趴教育官方平台或本协议所涉服务向您投放趴趴教育和/或第三人的商业广告。<br/>
9.趴趴教育有权监督您在趴趴教育官方平台上的使用本协议所涉服务的活动。您理解并知悉，如您被趴趴教育认定为通过趴趴教育官方平台从事本协议所述的禁止性活动和/或其他违法活动，趴趴教育有权立即停止向您提供本协议所涉服务而无须征得您的同意。<br/>
         </span>
    </div>
    <br/>


    <div class="title">
      五、协议的修改和完善
    </div><br/>
    <div class="privacyContent">
         <span class=" privacyContentColor">
1.趴趴教育将根据中国法律、法规的变化和业务发展的需要，随时单方面对本协议作出修订。<br/>
2.本协议如发生任何变更和/或修订，趴趴教育将会在趴趴教育官方平台重要页面上对您做出提示说明。您理解并知悉，如果您不同意所改动的内容，您可以立即注销您的用户账号，删除、卸载您已安装的趴趴教育官方平台软件，并终止本协议；如果您继续使用趴趴教育提供的本协议所涉服务，则视为您接受本协议变更和/或修订后的条款。如发生与本协议相关争议，则以本协议修订后公示的最新版本为准。<br/>
         </span>
    </div>
    <br/>
    <div class="title">
      六、服务的中断和终止
    </div><br/>
    <div class="privacyContent">
         <span class=" privacyContentColor">
1.您理解并知悉，如果您未能遵守本协议中的任一条款或条件，趴趴教育有权在不预先通知您的情况下随时终止本协议，并中断或终止向您提供本协议所涉服务。
         </span>
    </div>
    <br/>
    <div class="title">
      七、免责条款
    </div><br/>
    <div class="privacyContent">
         <span class=" privacyContentColor">
1.您理解并知悉，在使用本协议所涉服务的过程中，可能会遇到不可抗力等风险因素，使本协议所涉服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，趴趴教育将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失，趴趴教育在法律允许的范围内免责。<br/>
2.您理解并知悉，本协议所涉服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在地外部环境以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足您要求的风险。您因前述风险而遭受的任何损失，趴趴教育不承担任何责任。<br/>
3.您理解并知悉，趴趴教育需要定期或不定期地对趴趴教育官方平台或相关的设备进行检修或者维护。如遇此种情况，趴趴教育将事先向您发送通知，但对此类情况造成本协议所涉服务在合理时间内的中断，趴趴教育不承担任何责任。<br/>
4.趴趴教育依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成趴趴教育的义务或承诺，趴趴教育不能保证及时发现违法违规或违约行为或进行相应处理。<br/>
5.在任何情况下，趴趴教育均不对任何间接性、惩罚性、偶然性的损害，包括因您使用“本协议所涉服务而遭受的利润损失，承担责任（无论趴趴教育是否已被事先告知该损失的可能性）。尽管本协议中可能含有相悖的规定，趴趴教育对您承担的全部责任（如有），无论因何原因或何种行为方式，始终不超过您因使用趴趴教育提供的本协议所涉服务而支付给趴趴教育的费用。<br/>
         </span>
    </div>
    <br/>
    <div class="title">
      八、隐私政策
    </div><br/>
    <div class="privacyContent">
         <span class=" privacyContentColor">
1.趴趴教育致力于维护您个人信息的隐私性和安全性。您使用本协议所涉服务可能会导致趴趴教育需要对您个人信息进行处理，包括但不限于：收集、使用、处理、传输、存储或披露您的个人信息。趴趴教育及其服务提供商处理的个人信息将根据趴趴教育《隐私政策》受到额外约束。
         </span>
    </div>
    <br/>
    <div class="title">
      九、其他条款
    </div><br/>
    <div class="privacyContent">
      <span style="text-decoration: underline">1.趴趴教育郑重提醒您注意本协议中免除趴趴教育责任和限制您权利的条款。请您仔细阅读，自主考虑风险</span>
      <span class=" privacyContentColor">
如您根据您所在地法律，仍为未成年人，则应在您的法定监护人的陪同下阅读本协议。<br/>
2.您理解并知悉，本协议各条款标题仅是为了便于您快速理解本协议各条款内容所拟，各条款标题不属于本协议的一部分，不具有本协议所有的法律效力。<br/>
3.协议的终止和持续有效性<br/>
（1）本协议自您注册趴趴教育官方平台的首日生效。您可以随时终止本协议，方法是永久注销您的趴趴教育官方平台账户并卸载、删除您安装的趴趴教育官方平台，且自行承担相应的成本。<br/>
（2）本协议终止后，本协议第十条第4款、第十条第5款继续有效。[这里的第【】条是需要我们规定一下么]<br/>
4.适用法律和争议解决<br/>
（1）本协议条款的效力、履行、执行和解释均适用中华人民共和国大陆地区的法律，并且不适用其冲突法规则。<br/>
（2）您和趴趴教育一致同意，由本协议所涉服务引起的任何争议应由双方友好协商解决。如果在争议发生之日起45天内无法通过协商解决，由本协议产生或与本协议有关的任何争议、争论、差异或索赔，包括其存在、有效性、解释、履行、违约、终止或任何有关非协议义务的争议都应提交上海仲裁委员会根据现行的仲裁规则进行仲裁，仲裁的语言是中文，仲裁结果是终局的。<br/>
5.完整协议和可分割性<br/>
（1）本协议构成了您与趴趴教育之间达成的约束您使用本协议所涉服务行为的完整协议，并将取代之前您与趴趴教育达成的关于本协议所涉服务使用行为的任何协议。当您通过趴趴教育官方平台和/或使用趴趴教育提供的其他服务时，可能还需遵守其他适用的规则、协议和条件。<br/>
（2）如果本协议的任何条款由法院、仲裁机构或其他政府机关确定为非法、无效和/或不可执行的，并不影响本协议其他条款的效力，其他条款应继续有效。<br/>
         </span>
    </div>
    <br/>

    <div class="privacyContent">
         <span class=" privacyContentColor">
本用户协议最后更新日期为2019年9月18日。 请您随时关注本用户协议的更新及修改情况。 如您对本用户协议有任何问题，请联系我们的工作邮箱：developer@papaenglish.cn
         </span>
    </div>
    <br/>






  </div>
</template>

<script>
  export default {
    name: 'Agreement',
    metaInfo: {
      title: '趴趴教育唯一官方网站 - 雅思,托福,GMAT走心的在线英语培训平台',
      meta: [
        {
          name: 'keywords',
          content: '趴趴雅思,趴趴托福,趴趴GMAT,趴趴雅思小助手,雅思,托福,GMAT,PTE,实用英语'
        }
      ]
    }
  }
</script>

<style scoped>
  *{
    font-size: 14px
  }
  .fontWeight{
    font-weight: 700;
    margin-bottom: 10px;
  }
  .wrap{
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    box-sizing: border-box;
    padding: 80px ;
    color: #333;
  }
  .privacyContent{
    height: auto;
    word-wrap: break-word;
    margin-bottom: 10px;
    display: inline-block;
    text-align: justify;
  }
  .privacyContentColor{
    color: #666;
  }
  .title{
    font-weight: bold;
    font-size: 14px;
  }
</style>
